/**
 * Calculates and sets the price based on various parameters such as:
 * plot size, paper type, color option, and coverage percentage.
 * This function utilizes thresholds to differentiate between different types of print jobs
 * like CAD, graphic, and poster, and adjusts the price accordingly.
 * It also incorporates additional charges based on the paper type selected.
 *
 * @param {string} plotSize - The size of the plot, e.g., 'A0', 'A1', 'A2', 'A3'.
 * @param {string} paperSort - The type and weight of the paper, formatted as 'size_weight_type',
 *                             where type is optional and represents special characteristics like matte or glossy.
 * @param {string} percentage - The percentage of the area covered by the print.
 * @param {boolean} isColor - Indicates whether the print is colored.
 * @param {Function} setPrice - A callback function to set the calculated price in the state.
 */
export const calculatePrice = (plotSize: string, paperSort: string, percentage: string, isColor: boolean, setPrice: (price: number) => void) => {
    const basePrice = 0;
    const percentageInt = parseInt(percentage);
    let price = basePrice;

    // Thresholds for different types of print jobs
    const CAD_THRESHOLD = 20;
    const GRAPHIC_THRESHOLD = 50;

    // Price increments based on paper type and plot size
    const paperSortPrices: { [key: string]: { A3: number; A4: number } } = {
        '100': { A3: 0.10, A4: 0.05 },
        '120': { A3: 0.20, A4: 0.10 },
        '160': { A3: 0.30, A4: 0.15 },
        '200': { A3: 0.40, A4: 0.20 },
        '250': { A3: 0.60, A4: 0.30 },
        '300': { A3: 0.80, A4: 0.40 },
        '80c': { A3: 0.20, A4: 0.10 },   // color paper
        '250f': { A3: 2.00, A4: 1.00 },  // foto paper
        '80r': { A3: 0.10, A4: 0.05 }    // recycled paper
    };

    // Determine the applicable paper code based on paperSort input
    let paperCode: string | undefined;
    let paperWeight = paperSort.split('_')[1]; // Extract the weight
    let paperType = ''; // Extract the type, defaulting to an empty string if not present
    if (!paperSort.startsWith('p')) paperType = paperSort.split('_')[2] || ''; // Conditionally extracts the type
    paperCode = paperWeight + paperType;
    console.log("----------", plotSize);
    if (plotSize === 'A3') {
        // Pricing for A3 prints with special handling for color prints and specific paper types
        isColor ? price = 0.50 : price = 0.10;
        if (paperSortPrices[paperCode] && paperSortPrices[paperCode].A3) {
            price += paperSortPrices[paperCode].A3;
        }
    }
    else if (plotSize === 'A4') {
        // Pricing for A4 prints with special handling for color prints and specific paper types
        isColor ? price = 0.25 : price = 0.05;
        if (paperSortPrices[paperCode] && paperSortPrices[paperCode].A4) {
            price += paperSortPrices[paperCode].A4;
        }
    }
    else {
        // Pricing based on coverage percentage and plot size with specific increments for particular paper types
        if (percentageInt <= CAD_THRESHOLD) {  // CAD
            if (plotSize === 'A0') price = isColor ? 7.0 : 6.0;
            else if (plotSize === 'A1') price = isColor ? 5.0 : 4.0;
            else if (plotSize === 'A2') price = isColor ? 3.0 : 2.0;
        } else if (percentageInt <= GRAPHIC_THRESHOLD) {  // Grafik
            if (plotSize === 'A0') price = isColor ? 11.0 : 9.0;
            else if (plotSize === 'A1') price = isColor ? 9.0 : 7.0;
            else if (plotSize === 'A2') price = isColor ? 7.0 : 5.0;
        } else {  // Poster
            if (plotSize === 'A0') price = isColor ? 16.0 : 14.0;
            else if (plotSize === 'A1') price = isColor ? 12.0 : 10.0;
            else if (plotSize === 'A2') price = isColor ? 8.0 : 6.0;
        }

        // Adjust price for specific paper codes
        if (paperCode === '200') price += 2;
        else if (paperCode === '140') price += 1;
    }
    setPrice(price); // Update the price state using the callback function
};
